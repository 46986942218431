import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  Collapse,
  Button,
  Modal,
  ModalHeader,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Navbar from "components/HorizontalLayout/Navbar"
//i18n
import { withTranslation } from "react-i18next"
import { date } from "yup"
import { Link, useNavigate } from "react-router-dom"
import UTT from "../../assets/images/logo.png"
import jwtDecode from "common/TokenJwt/jwt-decode"
import encryptData from "common/Encrypt/encryptData"
import encryptDataOld from "common/Encrypt/encrypDataOld"
import classnames from "classnames"
import Loading from "components/Loading/loading"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard"
  const [activeTab, setactiveTab] = useState("1")
  const [openAccordions, setOpenAccordions] = useState([])
  const decodeToken = jwtDecode(localStorage.getItem("access_token"))

  const token = {
    id_jadwal_asesi: decodeToken.id_jadwal_asesi,
    nip: decodeToken.nip,
    mj_tipe_kegiatan: decodeToken.mj_tipe_kegiatan,
    mj_id_skala: decodeToken.mj_id_skala,
  }

  const tokenPsikotes = {
    nip: decodeToken.nip,
    noreg: decodeToken.kode_jadwal,
    password: decodeToken.password,
  }

  const secretKey = "sonsonz" // Replace with your secret key
  const encryptedToken = encryptData(token, secretKey)
  const encryptedTokenX = encryptedToken.replace(/\//g, "_")
  const encryptedTokenPsikotes = encryptDataOld(tokenPsikotes, secretKey)
  const id_jadwal = decodeToken.id_jadwal

  const link_psikotes = process.env.REACT_APP_CAT_URL + `/apiLogin?key=${encodeURIComponent(
    encryptedTokenPsikotes
  )}`

  const navigate = useNavigate()
  // const navigate = (path) => {
  //   // Your navigation logic here
  //   console.log('Navigating to:', path);
  // };

  const [data, setData] = useState()
  const [dataCat, setDataCat] = useState()
  const [dataBrief, setDataBrief] = useState()
  const [alatTest, setAlatTest] = useState()
  const [subTest, setSubTest] = useState()
  const [statusSoal, setStatusSoal] = useState()
  const [userData, setUserData] = useState()

  const [load, setLoad] = useState(true)

  const URL_DIGIMAC = "https://mac.bpkp.go.id/asesor/"

  const toggleAccordion = index => {
    const updatedOpenAccordions = [...openAccordions]
    updatedOpenAccordions[index] = !updatedOpenAccordions[index]
    setOpenAccordions(updatedOpenAccordions)
  }

  const currentDate = new Date().toLocaleDateString("en-CA")
  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })

  useEffect(() => {
    var myHeaders = new Headers()
    setLoad(true)
    setOpenAccordions([true])
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "AlatTest?" +
      "id_jadwal=" +
      id_jadwal +
      "&nip=" +
      decodeToken.nip +
      "&id_asesi=" +
      decodeToken.id_jadwal_asesi,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        console.log("Data AlatTest:", responseData)
        const status = responseData.data[0].status
        if (status) {
          setStatusSoal(status)
          setLoad(false)
          setData(responseData.data[0])
        } else {
          setData(responseData.data[0])
          setAlatTest(responseData.data[0].Data.id_mjadwal_alattest)
          setSubTest(responseData.data[0].Data.mjst_id_subtest)
          setLoad(false)
        }
      })
      .catch(error => console.log("error", error))

    if (token.id_jadwal_asesi) {
      const baseURL =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "materi?id_jadwal_asesi=" +
        token.id_jadwal_asesi
      fetch(baseURL, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.meta.code === 200) {
            setDataCat(responseData.data[0])
            setDataBrief(responseData.data[0].jadwal[0].mj_file_brief)
          }
          setLoad(false)
        })
        .catch(error => {
          setLoad(false)
        })
    }
  }, [])

  const fetchUserData = async (nip, token) => {
    const myHeaders = new Headers()
    myHeaders.append("Authorization", "Bearer " + token)

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    const baseURL = `${process.env.REACT_APP_BACKEND_URL_VERSION}user?nip=${nip}`

    try {
      const response = await fetch(baseURL, requestOptions)
      const result = await response.json()
      console.log("Data berdasarkan nip:", result)

      // Assuming result.data contains the array you want to display
      // console.table(result.data) // Display the data part in the console table

      setUserData(result) // Set userData with the fetched result
      return result // Return result for further processing if needed
    } catch (error) {
      console.log("Error:", error)
      throw error // Rethrow error to handle it in useEffect
    } finally {
      // Ensure loading state is updated in finally block
      setLoad(false)
    }
  }

  useEffect(() => {
    setLoad(true)
    const token = localStorage.getItem("access_token")
    const nip = decodeToken.nip

    fetchUserData(nip, token)
      .then(result => {
        console.log("data user saat ini:", result)
        // Additional processing if needed
      })
      .catch(error => {
        // Handle error if needed
        console.log("Fetch user data error:", error)
      })
  }, []) // Dependency array can be adjusted based on needs

  const param = {
    idSubtest: subTest,
    idAlattest: alatTest,
  }

  const encryptedTokenParam = encryptData(param, secretKey)

  const handleDownloadFile = async () => {
    const url = "https://mac.bpkp.go.id/asesor/"
    if (dataBrief) {
      const fileUrl = url + dataBrief
      const fileName = dataBrief.split("/").pop() // Extract the file name from the URL

      try {
        const response = await fetch(fileUrl)
        if (!response.ok) {
          throw new Error("Failed to fetch the file.")
        }

        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)

        const link = document.createElement("a")
        link.href = blobUrl
        link.download = fileName
        link.click()
      } catch (error) { }
    }
  }

  const [showModal, setShowModal] = useState(false)
  const [kodelink, setKode] = useState()
  const [linkZoom, setLink] = useState()
  const [idJadwal, setIdAsesi] = useState()
  const [idSimulasi, setIdSimulasi] = useState()

  const handleOpenModal = (asesi, simulasi, kode, link) => {
    setShowModal(true)
    setKode(kode)
    setLink(link)
    setIdAsesi(asesi)
    setIdSimulasi(simulasi)
  }

  const handleLinkClick = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `Status-simulasi?status_simulasi=proses&id_jadwal_asesi=${idJadwal}&id_simulasi=${idSimulasi}`,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(""))
      .catch(error => console.log(""))

    event.preventDefault()
    window.open(linkZoom, "_blank")
  }

  const JumperAC = (asesi, simulasi, tipe) => {
    const proctoring = localStorage.getItem("proctoring_status")
    window.location.href =
      `https://mac.bpkp.go.id/monitoring/catac/${encodeURIComponent(
        encryptedTokenX
      )}/` +
      asesi +
      "/" +
      simulasi +
      "/" +
      tipe +
      "/" +
      proctoring
  }

  // console.log(userData?.data[0]?.id_peserta_sipensi)
  // console.log(`https://pusbinjfa.bpkp.go.id/ujikomapi/pendaftaran/pesertaujikom/ujian-mansoskul/${userData?.data[0]?.id_peserta_sipensi}`)
  let idpeserta = userData?.data[0]?.id_peserta_sipensi

  function done() {
    window.location.href = "https://sibijak.bpkp.go.id/ujikomapp/app/beranda"
    // let isDone = 1
    // let jamStartUjian = "08:30:00"
    // let jamSubmitUjian = "10:11:00"
    // let payload = {
    //   isDone: 1,
    //   jamStartUjian: '08:30:00',
    //   jamSubmitUjian: '10:11:00'
    // }
    // let token = 'YuWtcq2h8UiY0GBi6nCxQjXA7Y2zzVNqvdZ4xUG3USw0ZQE4hG'
    // fetch(`https://sibijak.bpkp.go.id/ujikomapi_staging/api/pendaftaran/pesertaujikom/ujian-mansoskul/${idpeserta}?api_token=${token}`, {
    //   method: 'PUT',

    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(payload)
    // })
    //   .then(response => response.json())
    //   .then(res => {
    //     console.log(res)
    //     window.location.href = "https://sibijak.bpkp.go.id/ujikomapp/app/beranda"
    //   })
    //   .catch(error => {
    //     console.error("There was an error!", error);
    //   });
  }
  const logout = () => {
    navigate(`/logout`)
  }
  // Memastikan bahwa DataSelesai sudah ada sebelum mengaksesnya
  const jumlahDataSelesai = data?.DataSelesai?.length || 0

  // Menghitung jumlah status "belum dikerjakan"
  const jumlahBelumDikerjakan =
    data?.DataSelesai?.filter(item => item.ssst_status === "selesai").length ||
    0

  // Membuat kondisi jika jumlah status "belum dikerjakan" sama dengan jumlah DataSelesai
  const kondisiTerpenuhi = jumlahBelumDikerjakan === jumlahDataSelesai
  // const jumlahDataSelesai = 0
  // const jumlahBelumDikerjakan = 0
  // const kondisiTerpenuhi = false
  // console.log(data.DataSelesai)
  // if(data.DataSelesai !== null){
  // jumlahDataSelesai = data.DataSelesai.length;
  //   jumlahBelumDikerjakan = data.DataSelesai.filter(item => item.ssat_status !== "belum dikerjakan").length;
  //   kondisiTerpenuhi = jumlahBelumDikerjakan === jumlahDataSelesai;
  // }
  //  Menghitung jumlah DataSelesai
  //  const jumlahDataSelesai = data.DataSelesai.length;
  // Menghitung jumlah status "belum dikerjakan"
  //  const jumlahBelumDikerjakan = data.DataSelesai.filter(item => item.ssat_status !== "belum dikerjakan").length;

  // Membuat kondisi jika jumlah status "belum dikerjakan" sama dengan jumlah DataSelesai
  //  const kondisiTerpenuhi = jumlahBelumDikerjakan === jumlahDataSelesai;

  //  console.log(kondisiTerpenuhi)
  return (
    <React.Fragment>
      <div className="page-content">
        {load ? (
          <Loading />
        ) : (
          <Container>
            <Row>
              <div className="text-center mb-3">
                <div className="mt-5 alert alert-success w-100">
                  <div className="d-flex align-items-center">
                    <p
                      className="fs-5 fw-medium ms-3"
                      style={{ lineHeight: "1" }}
                    >
                      <i
                        className="mdi mdi-alert-circle"
                        style={{ lineHeight: "1" }}
                      ></i>{" "}
                      Kerjakan semua test dibawah dan mulailah sesuai urutan
                      kelompok soal
                    </p>
                  </div>
                </div>
              </div>
            </Row>
            {dataCat && dataCat.simulasi && (
              <Card className="shadow-md shadow border mb-2 mt-2">
                <CardBody>
                  <CardTitle className="mb-4">
                    <div className="bg-primary bg-soft p-3 rounded">
                      <h5 className="font-size-14 text-primary mb-0">
                        Simulasi
                      </h5>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl={12} className="d-flex justify-content-end">
                      <button
                        onClick={() => handleDownloadFile()}
                        className="btn btn-success mb-4 rounded-3"
                      >
                        <i className="fa fa-arrow-down"></i> Download File
                        Panduan
                      </button>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table align-middle mb-0 table-nowrap">
                      <thead className="table-light">
                        <tr className="text-center">
                          <th scope="col">No</th>
                          <th scope="col">Nama simulasi</th>
                          <th scope="col">Tanggal</th>
                          <th scope="col">Waktu</th>
                          <th scope="col">Link Zoom</th>
                          <th scope="col">Status</th>
                          <th scope="col">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataCat.simulasi.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.mjs_simulasi}({item.msn_mks_nama_kategori})
                            </td>
                            <td>
                              {item.mjs_tanggal_pelaksanaan_mulai} -{" "}
                              {item.mjs_tanggal_pelaksanaan_selesai}{" "}
                            </td>
                            <td>
                              {item.mjs_jam_pelaksanaan_mulai} -{" "}
                              {item.mjs_jam_pelaksanaan_selesai} WIB
                            </td>
                            <td>
                              {/* {item.msn_mks_nama_kategori !=
                                                "Tipe 8" && (
                                                  <Button
                                                    className="btn btn-soft-primary m-2"
                                                    onClick={() =>
                                                      handleChangePage(
                                                        item.id_jadwal_asesi,
                                                        item.mjs_id_simulasi,
                                                        item.msn_mks_nama_kategori
                                                      )
                                                    }
                                                  >
                                                    Mulai Kerjakan
                                                  </Button>
                                                )} */}
                              {item.mjs_link_zoom ? (
                                <Button
                                  className="btn btn-soft-primary"
                                  onClick={() =>
                                    handleOpenModal(
                                      item.id_jadwal_asesi,
                                      item.mjs_id_simulasi,
                                      item.mjs_kode_link_zoom,
                                      item.mjs_link_zoom
                                    )
                                  }
                                >
                                  <i className="fa fa-camera "></i> Link Zoom
                                </Button>
                              ) : (
                                <div className="badge p-2 bg-secondary rounded rounded-3 mt-2 mb-2">
                                  Tidak Tersedia
                                </div>
                              )}
                            </td>
                            {item.status == "selesai" ? (
                              <td>
                                <div className="badge p-2 bg-success rounded rounded-3 mt-2 mb-2">
                                  {item.status}
                                </div>
                              </td>
                            ) : item.status == "proses" ? (
                              <td>
                                <div className="badge p-2 bg-primary rounded rounded-3 mt-2 mb-2">
                                  {item.status}
                                </div>
                              </td>
                            ) : (
                              <td>
                                <div className="badge p-2 bg-danger rounded rounded-3 mt-2 mb-2">
                                  {item.status}
                                </div>
                              </td>
                            )}
                            <td>
                              {item.status != "selesai" &&
                                currentDate >=
                                item.mjs_tanggal_pelaksanaan_mulai &&
                                currentDate <=
                                item.mjs_tanggal_pelaksanaan_selesai &&
                                item.mjs_jam_pelaksanaan_mulai <= currentTime &&
                                item.mjs_jam_pelaksanaan_selesai >=
                                currentTime ? (
                                <>
                                  {item.status == "selesai" ? (
                                    <button
                                      className="btn btn-success rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Selesai
                                    </button>
                                  ) : item.status == "proses" ? (
                                    <button
                                      className="btn btn-info rounded rounded-3 mt-2 mb-2"
                                      onClick={() =>
                                        JumperAC(
                                          item.id_jadwal_asesi,
                                          item.mjs_id_simulasi,
                                          item.msn_mks_nama_kategori
                                        )
                                      }
                                    >
                                      Lanjutkan
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary rounded rounded-3 mt-2 mb-2"
                                      onClick={() =>
                                        JumperAC(
                                          item.id_jadwal_asesi,
                                          item.mjs_id_simulasi,
                                          item.msn_mks_nama_kategori
                                        )
                                      }
                                    >
                                      Mulai Kerjakan
                                    </button>
                                  )}
                                </>
                              ) : currentDate <=
                                item.mjs_tanggal_pelaksanaan_mulai ? (
                                <>
                                  <Button
                                    className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                    disabled={true}
                                  >
                                    Coming Soon
                                  </Button>
                                </>
                              ) : currentDate >=
                                item.mjs_tanggal_pelaksanaan_selesai ? (
                                <>
                                  {item.status === "selesai" ? (
                                    <Button
                                      className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Jadwal Selesai
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Jadwal Terlewat
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.status === "selesai" ? (
                                    <button
                                      className="btn btn-success rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Selesai
                                    </button>
                                  ) : (
                                    <Button
                                      className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Coming Soon
                                    </Button>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            )}
            <Row>
              <Col lg="12">
                <Card className="shadow-lg shadow border mt-2 mb-2">
                  <CardBody>
                    <CardTitle className="mb-4">
                      <div className="bg-primary bg-soft p-3 rounded">
                        <h5 className="font-size-14 text-primary mb-0">
                          Informasi Peserta
                        </h5>
                      </div>
                    </CardTitle>
                    {userData ? (
                      userData.data && userData.data.length > 0 ? (
                        <div className="table-responsive">
                          <Table className="table align-middle mb-0 table-nowrap table-striped">
                            <tbody>
                              {userData.data.map((item, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <th scope="row" className="text-start">
                                      Nama
                                    </th>
                                    <td className="text-center">:</td>
                                    <td className="text-start">
                                      {item.nama_peserta}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="text-start">
                                      NIP
                                    </th>
                                    <td className="text-center">:</td>
                                    <td className="text-start">{item.nip}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="text-start">
                                      Jabatan
                                    </th>
                                    <td className="text-center">:</td>
                                    <td className="text-start">
                                      {item.jabatan}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="text-start">
                                      Instansi
                                    </th>
                                    <td className="text-center">:</td>
                                    <td className="text-start">
                                      {item.instansi?.ins_nama}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="text-start">
                                      Jabatan Target
                                    </th>
                                    <td className="text-center">:</td>
                                    <td className="text-start">
                                      {item.jab_target_sipensi !== null
                                        ? item.jab_target_sipensi
                                        : "-"}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <p>No data available</p>
                      )
                    ) : (
                      <p>Loading user data...</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {data && (
              <Card className="shadow-lg shadow border mt-2 mb-2">
                <CardBody>
                  <CardTitle className="mb-4">
                    <div className="bg-primary bg-soft p-3 rounded">
                      <h5 className="font-size-14 text-primary mb-0">
                        Alat Test
                      </h5>
                    </div>
                  </CardTitle>
                  {data.Data && (
                    <div className="table-responsive">
                      <Table className="table align-middle mb-0 table-nowrap table-striped">
                        <thead className="table-light">
                          <tr className="text-center">
                            {/* <th scope="col">Alat Test</th>
                <th scope="col">Nomer Subtest</th> */}
                            <th scope="col">Nama Subtest</th>
                            <th scope="col">Waktu</th>
                            <th scope="col">Status</th>
                            <th scope="col">Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            {/* <td>{data.Data.mja_alattest}</td>
                <td>{data.Data.mjst_urutan_st}</td> */}
                            <td>{data.Data.mjst_subtest}</td>
                            <td>{data.Data.waktu} Menit</td>
                            <td>
                              {data.Data.ssst_status === "proses" ? (
                                <div className="badge p-2 bg-primary rounded rounded-3 mt-2 mb-2">
                                  {data.Data.ssst_status}
                                </div>
                              ) : (
                                <div className="badge p-2 bg-danger rounded rounded-3 mt-2 mb-2">
                                  {data.Data.ssst_status}
                                </div>
                              )}
                            </td>
                            {/* <td>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  navigate(
                                    `/Soal/${encodeURIComponent(
                                      encryptedTokenParam
                                    )}`
                                  )
                                }
                              >
                                {data.Data.ssst_status === "proses" ? (
                                  <>Lanjutkan</>
                                ) : (
                                  <>Mulai Kerjakan</>
                                )}
                              </button>
                            </td> */}
                            <td>
                              {data.Data.status != "selesai" &&
                                currentDate >=
                                data.Data.mjub_tanggal_pelaksanaan_mulai &&
                                currentDate <=
                                data.Data.mjub_tanggal_pelaksanaan_selesai &&
                                data.Data.mjub_jam_pelaksanaan_mulai <= currentTime &&
                                data.Data.mjub_jam_pelaksanaan_selesai >=
                                currentTime ? (
                                <>
                                  {data.Data.status == "selesai" ? (
                                    <button
                                      className="btn btn-success rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Selesai
                                    </button>
                                  ) : data.Data.status == "proses" ? (
                                    <button
                                      className="btn btn-info rounded rounded-3 mt-2 mb-2"
                                      onClick={() =>
                                        navigate(
                                          `/Soal/${encodeURIComponent(
                                            encryptedTokenParam
                                          )}`
                                        )
                                      }
                                    >
                                      Lanjutkan
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary rounded rounded-3 mt-2 mb-2"
                                      onClick={() =>
                                        navigate(
                                          `/Soal/${encodeURIComponent(
                                            encryptedTokenParam
                                          )}`
                                        )
                                      }
                                    >
                                      Mulai Kerjakan
                                    </button>
                                  )}
                                </>
                              ) : currentDate <=
                                data.Data.mjub_tanggal_pelaksanaan_mulai ? (
                                <>
                                  <Button
                                    className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                    disabled={true}
                                  >
                                    Coming Soon
                                  </Button>
                                </>
                              ) : currentDate >=
                                data.Data.mjub_tanggal_pelaksanaan_selesai ? (
                                <>
                                  {data.Data.status === "selesai" ? (
                                    <Button
                                      className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Jadwal Selesai
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Jadwal Terlewat
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {data.Data.status === "selesai" ? (
                                    <button
                                      className="btn btn-success rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Selesai
                                    </button>
                                  ) : (
                                    <Button
                                      className="btn-secondary rounded rounded-3 mt-2 mb-2"
                                      disabled={true}
                                    >
                                      Coming Soon
                                    </Button>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          {data.DataSelesai.map((item, index) => (
                            <tr key={index} className="text-center">
                              {data.Data.mjst_subtest !== item.mjst_subtest ? (
                                <>
                                  <td>{item.mjst_subtest}</td>
                                  <td>{item.waktu} Menit</td>
                                  <td>
                                    {item.ssst_status === "selesai" ? (
                                      <div className="badge p-2 bg-success rounded rounded-3">
                                        {item.ssst_status}
                                      </div>
                                    ) : (
                                      <div className="badge p-2 bg-danger rounded rounded-3">
                                        {item.ssst_status}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {item.ssst_status === "belum dikerjakan" && (
                                      <button
                                        className="btn btn-secondary"
                                        disabled
                                        onClick={() =>
                                          navigate(
                                            `/Soal/${encodeURIComponent(encryptedTokenParam)}`
                                          )
                                        }
                                      >
                                        Mulai Kerjakan
                                      </button>
                                    )}

                                    {item.ssst_status === "proses" && (
                                      <button
                                        className="btn btn-secondary"
                                        disabled
                                      >
                                        Sedang Diproses
                                      </button>
                                    )}

                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  {/* Cek apakah semua subtest telah selesai */}
                  {data.DataSelesai.every(
                    item => item.ssst_status === "selesai"
                  ) && (
                      <div className="mt-4">
                        <div className="table-responsive">
                          <Table className="table align-middle mb-0 table-nowrap table-striped">
                            <thead className="table-light">
                              <tr className="text-center">
                                <th scope="col">Nama Subtest</th>
                                <th scope="col">Waktu</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.DataSelesai.filter(
                                item => item.ssst_status === "selesai"
                              ).map((item, index) => (
                                <tr key={index} className="text-center">
                                  <td>{item.mjst_subtest}</td>
                                  <td>{item.waktu}</td>
                                  <td>
                                    <div className="badge p-2 bg-success rounded rounded-3">
                                      {item.ssst_status}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                </CardBody>
              </Card>
            )}

            {(token.mj_tipe_kegiatan == "psikotes_simulasi" ||
              token.mj_tipe_kegiatan == "psikotes") &&
              token.mj_id_skala !== null && (
                <Card className="shadow-md shadow border mb-0">
                  <CardBody>
                    <CardTitle className="mb-4">
                      <div className="bg-primary bg-soft p-3 rounded">
                        <h5 className="font-size-14 text-primary mb-0">
                          digiMAC - PSIKOTES
                        </h5>
                      </div>
                    </CardTitle>
                    <Row>
                      <Col xl={12} className="d-flex justify-content-end">
                        <a
                          href={link_psikotes}
                          className="btn btn-primary mb-4 rounded-3"
                        >
                          <i className="fa fa-arrow-down"></i> Menuju Halaman
                          Pengerjaan Piskotes
                        </a>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            <Row>
              <Col>
                {/* <div className="my-3 d-grid">
                  <button
                    className="btn btn-danger btn-block"
                    onClick={() => logout()}
                  >
                    Logout
                  </button>
                </div>
                  {kondisiTerpenuhi ? (
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => done()}
                      >
                        Selesai Mansoskul.
                      </button>
                    </div>
                  ):(
                    <div className="mt-3 d-grid">
                    </div>
                  )} */}
                {idpeserta !== null ? (
                  kondisiTerpenuhi ? (
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => done()}
                      >
                        Selesai Mansoskul.
                      </button>
                    </div>
                  ) : (
                    <div className="mt-3 d-grid"></div>
                  )
                ) : (
                  <div className="my-3 d-grid d-none">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={() => logout()}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <Modal
        isOpen={showModal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setShowModal(!showModal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setShowModal(!showModal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">{kodelink}</h4>
                <p className="text-muted font-size-14 mb-4">
                  Masuk zoom dengan kode diatas
                </p>

                <div className="input-group rounded bg-light">
                  <Input
                    readOnly
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                    value={linkZoom}
                  />
                  <a
                    href={linkZoom}
                    className="btn btn-primary"
                    color="primary"
                    type="button"
                    id="button-addon2"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                  >
                    <i className="bx bxs-paper-plane"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
